import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isNewTask, setIsNewTask] = useState(false);

  // Check session storage for authentication on load
  useEffect(() => {
    const session = sessionStorage.getItem('isAuthenticated');
    if (session === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
    setResult(null);
    setIsNewTask(false);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'audio/*' });

  const handleLogin = (e) => {
    e.preventDefault();
    // Simple authentication logic
    if (username === 'galleryvision' && password === 'eTP5PxarpCIbd') {
      setIsAuthenticated(true);
      sessionStorage.setItem('isAuthenticated', 'true'); // Save session state
    } else {
      alert('Invalid credentials. Please try again.');
    }
  };

  const handleSubmit = async () => {
    if (!selectedFile) {
      alert('Please select a file.');
      return;
    }

    setLoading(true);
    setResult(null);
    setIsNewTask(false);

    const formData = new FormData();
    formData.append('audioFile', selectedFile);
    try {
      const response = await axios.post('https://ai.gallery.vision/api/upload-audio', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const aiResult = response.data.aiReport;
      setResult({
        isAI: aiResult.isAi,
        confidence: aiResult.confidence,
      });
      setIsNewTask(true);
    } catch (error) {
      setResult({ error: 'An error occurred. Please try again.' });
    } finally {
      setLoading(false);
    }
  };

  const handleNewTask = () => {
    setSelectedFile(null);
    setResult(null);
    setIsNewTask(false);
  };

  // If not authenticated, show the login page
  if (!isAuthenticated) {
    return (
      <div className="login-page">
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <input
            type="text"
            placeholder="Username"
            className='input'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            className='input'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit"><span>Login</span></button>
        </form>
      </div>
    );
  }

  // Main app content
  return (
    <div className="App">
      <h1 className="title">GV AI Song Detector</h1>

      {!loading && (
        <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the file here ...</p>
          ) : selectedFile ? (
            <p>Selected File: {selectedFile.name}</p>
          ) : (
            <p>Drag & Drop an audio file here, or click to select one</p>
          )}
        </div>
      )}

      <button onClick={isNewTask ? handleNewTask : handleSubmit} disabled={!selectedFile || loading}>
        <span>{loading ? 'Processing...' : isNewTask ? 'New Task' : 'Check if AI'}</span>
      </button>

      {loading && <div className="loader"></div>}

      {result && (
        <div className="result">
          {result.error ? (
            <p>
              {result.error}
              <br /> You may use{' '}
              <a href="https://app.ircamamplify.io/" target="_blank" rel="noopener noreferrer">
                this link
              </a>{' '}
              if the issue still persists.
            </p>
          ) : (
            <div>
              <p>AI-Generated: {result.isAI ? 'Yes' : 'No'}</p>
              <p>Confidence: {result.confidence}%</p>
            </div>
          )}
        </div>
      )}
	<footer className="watermark">
        <p>Developed by Nasim</p>
      </footer>
    </div>
  );
}

export default App;
